<template>
  <div class="notice">
    <div class="notice_searchBox">
      <searchV1
        class="notice_searchBox_item"
        v-model="keyword"
        @search="search"
        @clear="search"
      ></searchV1>
    </div>
    <div class="notice_body">
      <scrollview @onRefresh="onRefresh" @onReload="onReload" :height="scrollHeight">
        <template v-if="list.length > 0">
          <div class="all_checked">
            <nut-checkbox v-model="checkedAll"   label="全选" @change="checkAllboxChange">
            </nut-checkbox>
            <nut-button
                    type="light"
                    shape="circle"
                    small
                    color="#eb6100"
                    @click="makeFlowCode()"
            >
              制码核销
            </nut-button>
          </div>
          <div class="card" v-for="(item, index) in list" :key="index">

            <div class="card-title">
              <cell-v3 :hide-icon="true">
                <div class="title" slot="title">
                  <nut-checkbox v-model="item.create_time" :checked="checkOne(index)"  @change="checkBoxChange(index,item.create_time)">
                  </nut-checkbox>
                  编码：{{ item.flow_up_code }}
                </div>
                <div class="actions" @click="showOrHide(index)">
                  <span>订单详情</span>
                  <img
                    v-if="item.hide"
                    :src="require('@/static/image/icons/arrowDown.png')"
                  />
                  <img
                    v-else
                    :src="require('@/static/image/icons/arrowUp.png')"
                  />
                </div>
              </cell-v3>
            </div>
            <div class="card-body">
              <div
                class="order-sn-container"
                @click="onShowCode(item.flow_up_code_url)"
              >
                <img :src="require('@/static/image/icons/qrcode.png')" />
                订单码
              </div>
              <div v-show="item.hide == false">
                <div class="goods-list">
                  <div
                    class="goods"
                    v-for="(goods, index) in item.goodList"
                    :key="index"
                  >
                    <goods-v4
                      :img-url="goods.cover"
                      :title="goods.title"
                      :number="goods.number"
                    />
                  </div>
                </div>
                <div class="contact-info">
                  <div class="contact-line">
                    <cell-v3 :hideIcon="true">
                      <div class="contact-info-label" slot="title">
                        联系人：{{ item.site_leader }}
                      </div>
                    </cell-v3>
                  </div>
                  <div class="contact-line">
                    <cell-v3 :hideIcon="true">
                      <div class="contact-info-label" slot="title">
                        联系方式：{{ item.mobile }}
                      </div>
                      <div class="actions" @click="contact(item.mobile)">
                        <img
                          :src="require('@/static/image/icons/mobile.png')"
                        />
                        联系他
                      </div>
                    </cell-v3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </scrollview>
    </div>
  </div>
</template>

<script>
import searchV1 from "../../template/community/search/searchV1";
import CellV3 from "../../template/community/cell/cellV3";
import GoodsV4 from "../../template/community/goods/GoodsV4";
import item from "@nutui/nutui/dist/packages/noticebar/item";

export default {
  name: "send-notice-list",
  components: {
    GoodsV4,
    CellV3,
    searchV1,
  },
  data() {
    return {
      point_id: 0,
      params: {
        page: 1,
        page_size: 10,
      },
      list: [],
      checkList:[],
      checkedAll:false,
      keyword: "",
      scrollHeight:0,
    };
  },

  mounted() {
    let query = this.$route.query;
    this.point_id = query.site_point_id;
    this.getStoreWaitReceiveList();
    this.scrollHeight = window.innerHeight - document.querySelector('.notice_searchBox').clientHeight
  },
  methods: {
    onRefresh(done) {
      this.list = [];
      this.params.page = 1;
      this.getStoreWaitReceiveList().finally(() => {
        done();
      });
    },
    checkOne(id){ //判断当前选项是否选中
        if (this.checkList.includes(id)){
          return true
        }else{
          return false
        }
    },
    checkBoxChange(index,val){

        if (val){ //如果是选中状态
             console.log("add",index);
            this.checkList.push(index)
        }else{
          let sliceIndex=this.checkList.indexOf(index)
          console.log(sliceIndex)
          if (sliceIndex!=-1){
            console.log("del",sliceIndex);
            this.checkList.splice(sliceIndex,1)
          }
          if (this.checkList.length==0){
            this.checkedAll=false
          }
        }

    },
    checkAllboxChange(checked){
        if (checked==false){
          this.checkList=[]
        }else{
          this.checkList=[]
          this.list.forEach((key,val) => {
            this.checkList.push(val)
          })
        }
       // console.log(this.checkList)
    },
    /**
     * step 当前加载结束
     * over 没有更多数据了
     */
    onReload(step, over) {
      console.log("上拉加载");
      this.params.page += 1;
      this.getStoreWaitReceiveList().finally(() => {
        if (this.list.length < this.params.page * this.params.page_size) {
          over();
        } else {
          step();
        }
      });
    },
    async getStoreWaitReceiveList() {
      try {
        let query = await this.$api.community.courier.getSendNoticeList({
          ...this.params,
          point_id: this.point_id,
          keyword: this.keyword,
        });
        let list = query.data;
        if (list.length == 0) {
          return;
        }
        list = list.map((item) => {
          return {
            ...item,
            hide: false,
          };
        });
        this.list = [...this.list, ...list];
      } catch (e) {
        // console.log("结果获取失败");
      }
    },
    search() {
      this.page = 1;
      this.list = [];
      this.getStoreWaitReceiveList();
    },
    showPopup(popup_url) {
      this.popupUrl = popup_url;
      this.$refs.popup.show();
    },
    showOrHide(index) {
      this.list[index].hide = !this.list[index].hide;
    },
    onShowCode(code) {
      this.$showCodeV1({
        title: "请由收货点进行扫码收货", // 标题
        codeUrl: code, //二维码链接地址
        confirmText: "确定", // 确认文字
        success: () => {
          // 确认执行函数
          console.log("确定");
        },
      });
    },
    async makeFlowCode() {

      try {
        let checkIds=[]
        this.list.forEach((v,k)=>{
           if (this.checkList.includes(k)){
             checkIds.push(v.id)
           }
        })

        let query = await this.$api.community.courier.makeFlowCode({
          extendIds:checkIds
        });
        if (query.data){
          this.$showCodeV1({
            title: "请由收货点进行扫码收货", // 标题
            codeUrl: query.data, //二维码链接地址
            confirmText: "确定", // 确认文字
            success: () => {
              // 确认执行函数
              console.log("确定");
            },
          });

        }else{
          this.$notify.warn(query.message);
        }


      } catch (e) {
          this.$notify.warn(e.message);
      }
    },
    //联系负责人
    contact(mobile) {
      console.log("联系负责人");
      let self = this;
      this.$dialog({
        title: "温馨提示",
        content: `确定要给${mobile}打电话吗？`,
        closeOnClickModal: false, //点击蒙层是否关闭对话框
        closeBtn: false, //显式右上角关闭按钮
        onOkBtn() {
          self.$dialog.closed();
          if (self.$platform.wxsdk.isWechat()) {
            self.$platform.wxsdk.wxRoute({
              type: "navigateTo",
              url: "/web/makePhoneCall/common?mobile=" + mobile,
            });
          } else {
            window.location.href = "tel:" + mobile;
          }
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@include b(notice) {
  width: 100vw;
  height: 100vh;
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  @include e(searchBox) {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    @include e(item) {
      width: 345px;
      height: 32px;
    }
  }
  @include e(body) {
    flex-grow: 1;
    box-sizing: border-box;
    padding: 15px 0;
  }
}
.all_checked{
  display: flex;
  padding: 10px 0;
  border-radius: 10px;
  align-items:center;/*垂直居中*/
  flex-wrap: wrap;
  margin: 0 auto;
  width: 325px;
  margin-bottom: 5px;
}
.card {
  display: flex;
  background: #fff;
  padding: 15px;
  border-radius: 10px;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-bottom: 15px;
  width: 345px;
  box-sizing: border-box;
  .card-title {
    width: 100%;
    color: #333333;
    font-size: 16px;

    .actions {
      display: flex;
      justify-content: flex-end;

      img {
        margin-left: 5px;
        width: 21px;
        height: 21px;
      }
    }
  }

  .card-body {
    width: 100%;

    .order-sn-container {
      width: 100%;
      display: flex;
      background: #ffece1;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #f46a17;
      padding: 15px 0;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      margin-top: 20px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 2px;
      }
    }

    .goods-list {
      width: 100%;
      border-top: #ebebeb solid 1px;
      border-bottom: #ebebeb solid 1px;
      padding-bottom: 15px;
      margin-top: 15px;

      .goods {
        margin-top: 15px;
      }
    }
  }
}
.contact-info {
  width: 100%;

  .contact-line {
    margin-top: 15px;
    color: #333;
    font-size: 14px;

    .actions {
      display: flex;
      color: #f46a17;

      img {
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }
    }
  }
}
</style>
